<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>订单查看</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              {{ orderInfo.orderSn }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="客户">
              {{ orderInfo.orderCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商家">
              {{ orderInfo.producerCorporationName }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单类别">
              {{ orderInfo.orderTypeValue }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="申请时间">
              {{ orderInfo.createTime ? tsFormat(orderInfo.createTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单状态">
              <span
                v-if="
                  fromRouterName === 'FactoryReturnedOrderList' ||
                    storeRouterName === 'FactoryReturnedOrderList'
                "
              >
                {{ orderInfo.nodeStatus }}
              </span>
              <div v-else>
                <span
                  class="status--orange"
                  v-if="orderInfo.orderStatusValue === '待退款'"
                >
                  {{ orderInfo.orderStatusValue }}
                </span>
                <span v-else>{{ orderInfo.orderStatusValue }}</span>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="原订单号">
              <a>
                <span @click="onCheckPurchaseOrder(orderInfo.orderPurchaseSn)">
                  {{ orderInfo.orderPurchaseSn }}
                </span>
              </a>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">收货信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名">
              {{ orderInfo.consigneeRealName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话">
              {{ orderInfo.consigneeTel }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址">
              {{ orderInfo.consigneeAddress }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">商品信息</div>
      <a-table
        class="commodity__table"
        :data-source="commodityData"
        :columns="commodityColumns"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      ></a-table>
    </section>
    <section class="card__container">
      <div class="container__title">退款金额</div>
      <a-table
        class="flow__table"
        :columns="flowColumns"
        :data-source="flowList"
        :loading="flowLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="huifuSeqId" slot-scope="text">
          {{ text || "-" }}
        </template>
        <template slot="paymentTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
      </a-table>
    </section>
    <section
      class="card__container"
      :class="type === 'detail' ? 'low__bottom' : 'high__bottom'"
    >
      <div class="container__title between">
        流程日志
        <a @click="onShowProcessImg" class="process__img">点击查看流程图</a>
        <div v-viewer class="images">
          <img
            src="../../../assets/imgs/process/returnedOrder.png"
            :data-source="
              require('../../../assets/imgs/process/returnedOrder.png')
            "
            alt="流程图"
          />
        </div>
      </div>
      <a-table
        class="log__table"
        :columns="logColumns"
        :data-source="logData"
        :loading="logLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="reason" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
      </a-table>
    </section>
    <section class="examine__container" v-if="type === 'refunded'">
      <div class="operation">
        <a-button type="primary" @click="onOpenPayModal">去退款</a-button>
      </div>
    </section>

    <a-modal
      :visible="payModal"
      title="确认付款金额"
      width="40%"
      @cancel="onCancelModal"
      @ok="onConfirmPay"
      :confirm-loading="confirmLoading"
    >
      <div class="pay__num">
        实付¥
        <div v-if="orderInfo.instantBill">
          {{ orderInfo.instantBill.factoryActualPayment }}
        </div>
      </div>
      <div class="total__num">
        订单金额：¥
        <div v-if="orderInfo.instantBill">
          {{ orderInfo.instantBill.factoryTotalPrice }}
        </div>
      </div>
      <a-divider style="margin-bottom: 12px" />
      <div class="pay__item">
        <div class="pay__item--logo">
          <img src="../../../assets/order/pay.png" />
          线下支付
        </div>
        <a-radio-group :default-value="1">
          <a-radio :value="1" />
        </a-radio-group>
      </div>
      <a-divider style="margin-top: 12px" />
    </a-modal>

    <!--  支付结果弹窗  -->
    <a-modal
      :visible="payResultModal"
      width="50%"
      title="支付结果"
      @cancel="onConfirmPayResult"
    >
      <template slot="footer">
        <a-button type="primary" @click="onConfirmPayResult">
          <span v-if="payResult">确认（{{ second }}s）</span>
          <span v-else>确认</span>
        </a-button>
      </template>
      <div class="logo__container">
        <img src="../../../assets/order/icon_success.png" v-if="payResult" />
        <img src="../../../assets/order/icon_fail.png" v-if="!payResult" />
        <span v-if="payResult">支付成功</span>
        <span v-if="!payResult">支付失败</span>
      </div>
      <a-divider />
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="12">
            <a-form-item label="交易时间">
              {{ payInfo.paymentTime ? tsFormat(payInfo.paymentTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="交易单号">
              {{ payInfo.billId }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="订单金额">
              ¥{{ payInfo.totalPrice }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="实付款">
              ¥{{ payInfo.actualPayment }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="!payResult">
          <a-col :span="24">
            <a-form-item label="失败原因">
              {{ errorMsg }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { pageDetailMixins } from "@/components/pageDetailMixin";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import {
  fetchOrderDetail,
  fetchOrderLog,
  fetchPayResult,
  ordersOfflinePaymentPost
} from "@/services/OrderManageService";
import { tsFormat } from "@/components/DateUtils";

const commodityColumns = [
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "20%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "35%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "退货数量",
    dataIndex: "purchaseQuantity",
    width: "15%"
  },
  {
    title: "出厂总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "15%"
  }
];
const refundedColumns = [
  {
    title: "退款机构",
    dataIndex: "paymentCorporationName",
    width: "20%"
  },
  {
    title: "收款机构",
    dataIndex: "receiveCorporationName",
    width: "20%"
  },
  {
    title: "退款金额（元）",
    dataIndex: "actualPayment",
    width: "20%"
  },
  {
    title: "退款时间",
    dataIndex: "paymentTime",
    scopedSlots: { customRender: "paymentTime" },
    width: "20%"
  },
  {
    title: "状态",
    dataIndex: "paymentStatus",
    width: "20%"
  }
];
const logColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: "20%"
  },
  {
    title: "所属渠道类型",
    dataIndex: "distributorType",
    width: "15%"
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "15%"
  },
  {
    title: "审核意见",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    width: "30%"
  }
];
const flowColumns = [
  {
    title: "流水号",
    dataIndex: "huifuSeqId",
    scopedSlots: { customRender: "huifuSeqId" },
    width: "15%"
  },
  {
    title: "付款机构",
    dataIndex: "paymentCorporationName",
    width: "20%"
  },
  {
    title: "收款机构",
    dataIndex: "receiveCorporationName",
    width: "20%"
  },
  {
    title: "应付款金额（元）",
    dataIndex: "actualPayment",
    width: "10%"
  },
  // {
  //   title: "实际付款金额（元）",
  //   dataIndex: "accountPaid",
  //   width: "10%"
  // },
  {
    title: "付款时间",
    dataIndex: "paymentTime",
    scopedSlots: { customRender: "paymentTime" },
    width: "15%"
  },
  {
    title: "状态",
    dataIndex: "paymentStatus",
    width: "10%"
  }
];

export default {
  name: "ToRefundedDetail",
  components: {
    Breadcrumb,
    BreadcrumbItem
  },
  mixins: [pageDetailMixins],
  data() {
    return {
      orderSn: this.$route.params.orderSn,
      type: this.$route.params.type,
      orderInfo: {},
      commodityData: [],
      commodityColumns,
      loading: false,
      refundedColumns,
      refundedData: [],
      refundedLoading: false,
      logColumns,
      logData: [],
      logLoading: false,
      payModal: false,
      confirmLoading: false,
      payResultModal: false,
      payResult: false,
      second: 5,
      payInfo: {},
      errorMsg: "",
      tsFormat,
      fromRouterName: "",
      storeRouterName: "",
      flowColumns,
      flowList: [],
      flowLoading: false
    };
  },
  watch: {
    second: {
      handler(newVal) {
        if (newVal === 0) {
          this.$router.push({ name: "ToRefundedList" });
        }
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    this.loadDetail();
    this.loadLogList();
    this.loadPayBill();
  },
  methods: {
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    // 加载详情
    loadDetail() {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: "factory-refund"
      };
      fetchOrderDetail(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            this.orderInfo = resp.data.data;
            if (
              this.orderInfo.instantOrderSkus &&
              this.orderInfo.instantOrderSkus.length > 0
            ) {
              this.commodityData = this.calculate(
                this.orderInfo.instantOrderSkus
              );
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 加载流程日志
    loadLogList() {
      this.logLoading = true;
      fetchOrderLog(this.orderSn, "producer").then(resp => {
        this.logLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.logData = resp.data.data;
        }
      });
    },
    // 计算
    calculate(list) {
      let arr = [...list];
      if (list && list.length > 0) {
        let num = 0;
        let factoryPrice = 0;
        list.forEach(item => {
          num = num + parseInt(item.purchaseQuantity);
          factoryPrice = factoryPrice + parseFloat(item.skuFactoryTotalPrice);
        });
        const params = {
          skuCode: "合计",
          purchaseQuantity: num,
          skuFactoryTotalPrice: factoryPrice.toFixed(2)
        };
        arr.push(params);
      }
      return arr;
    },
    // 打开支付弹窗
    onOpenPayModal() {
      this.payModal = true;
    },
    // 取消打开支付弹窗
    onCancelModal() {
      this.payModal = false;
    },
    // 确认支付弹窗
    onConfirmPay() {
      this.payModal = false;
      ordersOfflinePaymentPost({
        orderSn: this.orderSn,
        type: "account"
      }).then(res => {
        if (res.data.code === "SUCCESS") {
          this.$message.success("操作成功");
          this.$router.back();
          // if (res.data.data) {
          //   this.$message.success("操作成功");
          //   this.$router.push({
          //     name: "CashierDetail",
          //     query: {
          //       type: "pay",
          //       rn: res.data.data,
          //       relId: this.orderSn
          //     }
          //   });
          // } else {
          //   this.$message.success("操作成功");
          //   this.$router.back();
          // }
        }
      });
    },
    // 加载支付详情
    loadPayDetail() {
      fetchPayResult(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data && data.length > 0) {
            let item = data.find(
              ele =>
                ele.paymentCorporationId ===
                localStorage.getItem("corporationId")
            );
            this.payInfo = item;
          }
          if (this.payResult) {
            let timer = setInterval(() => {
              this.second--;
            }, 1000);
            this.$once("hook:beforeDestroy", () => {
              clearInterval(timer);
              timer = null;
            });
          }
        }
      });
    },
    // 确认支付结果
    onConfirmPayResult() {
      this.$router.push({ name: "ToRefundedList" });
    },
    // 查看采购订单
    onCheckPurchaseOrder(orderSn) {
      const str = orderSn.substring(0, 2);
      if (str === "YQ") {
        const routeData = this.$router.resolve({
          name: "ForwardPurchaseSubOrder",
          params: { orderSn: orderSn, status: "payment-approval" }
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "PurchaseOrderDetailFactory",
          params: { orderSn: orderSn, type: "detail" }
        });
        window.open(routeData.href, "_blank");
      }
    },
    // 查付款单
    loadPayBill() {
      this.flowLoading = true;
      fetchPayResult(this.orderSn)
        .then(resp => {
          this.flowLoading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data && data.length > 0) {
              this.flowList = data;
            }
          }
        })
        .catch(() => {
          this.flowLoading = false;
        });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.tip {
  display: flex;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

.top {
  margin-top: 10px;
}

.price__item {
  width: 70%;
  min-width: 1080px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(164, 173, 179, 0.6);
  box-sizing: border-box;
  padding: 13px 10px;
  margin-top: 20px;
}

.price__item--value {
  flex: 1;
  display: flex;
  margin: 0 10%;
}

.label {
  min-width: 100px;
}

.right {
  margin-right: 26%;
}

.left {
  margin-left: 26%;
}

.commodity__table {
  margin-top: 20px;
}

.between {
  display: flex;
  justify-content: space-between;
}

.images img {
  display: none;
}

.process__img {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-weight: unset;
}

.high__bottom {
  margin-bottom: 150px;
}

.low__bottom {
  margin-bottom: 30px;
}

.examine__container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 20px 20px 220px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.examine__form /deep/ .ant-form-item {
  display: flex;
  margin: 0 40px 0 0;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

.top {
  margin-top: 10px;
}

.operation {
  display: flex;
  justify-content: flex-end;
}

.log__table {
  margin-top: 20px;
}

.status--orange {
  color: rgba(255, 134, 0, 1);
}

.radio__item {
  margin-bottom: 10px;
}

.address__container {
  margin-top: 20px;
}

.refunded__table {
  margin-top: 20px;
}

.pay__num {
  display: flex;
  color: rgba(255, 0, 0, 1);
  font-size: 14px;
  font-weight: bold;
  align-items: flex-end;
  justify-content: center;
}

.pay__num div {
  font-size: 28px;
  transform: translateY(6px);
}

.total__num {
  display: flex;
  justify-content: center;
  color: rgba(16, 16, 16, 1);
  font-weight: 500;
  margin-top: 15px;
}

.pay__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pay__item--logo {
  display: flex;
  align-items: center;
}

.pay__item--logo img {
  width: 38px;
  height: 38px;
  margin-right: 5px;
}

.logo__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo__container img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.logo__container span {
  color: rgba(102, 102, 102, 1);
  font-size: 28px;
  font-weight: 500;
}

.high__bottom {
  margin-bottom: 150px;
}

.low__bottom {
  margin-bottom: 30px;
}

.flow__table {
  margin-top: 20px;
}
</style>
